<template lang="pug">
Container
  h3(style="text-align:center") Политика конфиденциальности мобильного приложения “Сервис полевой работы”
  p.chapter 1.  Основные понятия, используемые в Политике конфиденциальности персональных данных
  table
    thead
      th Понятия
      th Определение
    tbody
      tr
        td Администрация Сервисов
        td Уполномоченные сотрудники ГНС при Министерстве финансов Кыргызской Республики на управление Сервисами, которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными
      tr
        td Сайт и мобильное приложение
        td сайт #[a.link(href="kdk.salyk.kg") kdk.salyk.kg] и мобильное приложение “Сервис полевой работы” и иные интернет-сервисы, которые ссылаются на настоящую Политику конфиденциальности персональных данных, и через которые ГНС при Министерстве финансов Кыргызской Республики собирает персональные данные любых лиц, посещающих указанные сайты
      tr
        td Пользователь Сервисов
        td Лицо, имеющее доступ к Сервисам посредством сети Интернет и использующий данные Сервисы (субъект персональных данных)
      tr
        td Персональные данные
        td Любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу  - Пользователю Сервисов (субъекту персональных данных)
      tr
        td Специальные категории персональных данных
        td Персональные данные, касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни
      tr
        td Конфиденциальность персональных данных
        td Обязательное для соблюдения Администрацией Сервисов требование не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено законодательством Кыргызской Республики
      tr
        td Обработка персональных данных
        td Любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных
      tr
        td Обработка персональных данных с использованием средств автоматизации
        td Обработка персональных данных с помощью средств вычислительной техники
      tr
        td Распространение персональных данных
        td Действия, направленные на раскрытие персональных данных неопределенному кругу лиц
      tr
        td Предоставление персональных данных
        td Действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц
      tr
        td Блокирование персональных данных
        td Временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных)
      tr
        td Уничтожение персональных данных
        td Действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных
      tr
        td IP-адрес
        td Уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP
      tr
        td Cookie
        td Это небольшой текстовый файл, который веб-сервер размещает на жестком диске компьютера Пользователя. Сookie-файлы могут быть как «сеансовые», так и «постоянные»

  p.chapter 2.  Общие положения
  p 2.1. Настоящая Политика конфиденциальности персональных данных ГНС при Министерстве финансов Кыргызской Республики (далее – Политика конфиденциальности) применяется при использовании сайта #[a.link(href="kdk.salyk.kg") kdk.salyk.kg] и мобильного приложения “Сервис полевой работы”, которые ссылаются на настоящую Политику конфиденциальности, и через которые ГНС при Министерстве финансов Кыргызской Республики собирает персональные данные любых лиц, посещающих указанный сайт и использует Мобильное приложение (далее Сервисы).
  p 2.2. Использование сервисов Пользователем означает его безоговорочное согласие с Политикой конфиденциальности и условиями обработки персональных данных Пользователя Сервисов.
  p 2.3. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сервисов.
  p 2.4. Политика конфиденциальности определяет основные цели и условия обработки персональных данных Пользователей, и сведения о реализуемых требованиях к защите персональных данных Пользователей Сервисов.
  p 2.5. Политика конфиденциальности применяется только к Сервисам. Администрация Сервисов не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь Сервиса может перейти по ссылкам, доступным на Сервисе.
  p 2.6. Администрация Сервисов является оператором только тех персональных данных, которые получает от Пользователей Сервиса при использовании Сервисов с их согласия, предоставляемого путем совершения Пользователем конклюдентных действий на Сервисы.
  p 2.7. Пользователь Сервисов подтверждает, что, предоставляя свои персональные данные и давая согласие на их обработку, он действует добровольно, своей волей и в своем интересе, а также подтверждает свою дееспособность.
  p 2.8. Предоставляя свои персональные данные, Пользователь Сервисов соглашается с тем, что предоставленные им персональные данные будут обрабатываться ГНС при Министерстве финансов Кыргызской Республики (адрес: 720001, г. Бишкек, пр. Чуй, 219, ИНН 00305200510030) как с использованием средств автоматизации, так и без использования таких средств.
  p 2.9. Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности, не являются специальными или биометрическими. Персональные данные предоставляются Пользователем Сервисов путем заполнения специальных форм на бессрочно (вплоть до отзыва Пользователем Сервисов своего согласия на обработку персональных данных). В зависимости от заполняемой Пользователем веб-формы, данные могут включать в себя следующую информацию:
  ul
    li ИНН;
    li Фамилию, имя, отчество;
    li Дату рождения;
    li Контактный телефон;
    li Адрес электронной почты (e-mail);
    li Название организации (места работы);
    li должность.
  p Заполняя соответствующие веб-формы и/или отправляя свои персональные данные Администрации Сервисов, Пользователь  выражает свое согласие с Политикой конфиденциальности.
  p 2.10 Администрация Сервисов также принимает меры по защите персональных данных, которые автоматически передаются в процессе посещения страниц Сайта и использования мобильного приложения, в том числе из файлов cookie:
  ul
    li IP адрес, присвоенный компьютеру Пользователя в момент посещения Сервисов;
    li Местоположение Пользователя при использовании МП;
    li Модель и версия операционной системы мобильного устройства при использовании МП;
    li Полученные данные о сессиях.
  p.chapter Сookie-файлы могут быть как «сеансовые», так и «постоянные».
  p.chapter Сеансовые cookie-файлы
  span Администрация Сервисов использует сеансовые cookie-файлы, чтобы присвоить уникальный идентификационный номер компьютеру Пользователя Сервисов при каждом посещении Сайта, при этом после закрытия браузера они удаляются. Такие файлы применяются также для анализа работы Пользователя Сервисов с Сайтом (изучается посещение страниц, используемых ссылок и время пребывания Пользователя на той или иной странице).
  p.chapter Постоянные cookie-файлы
  span Сайт распознает постоянные cookie, которые хранятся на жестких дисках компьютеров Пользователей, и, присваивая устройствам Пользователей уникальные идентификаторы, Администрация Сервисов может создать базу данных о действиях и предпочтениях Пользователей (в частности, о частоте посещения и периодичности возвращения Пользователей, об их предпочтениях на Сайте). Важно, что cookie-файлы не содержат личных данных Пользователей, они только фиксируют их действия.
  p.chapter Согласие на использование cookie
  span Сеансовые cookie-файлы не требуют предварительного согласия Пользователей Сайта, постоянные cookie-файлы такого согласия требуют.
  span Управлять cookie-файлами Пользователи могут через настройки веб-браузера. Таким образом, у них есть возможность разрешить использование всех cookie-файлов, интегрированных в страницы или заблокировать cookie-файлы, а также включить использование cookie-файлов по запросу, принимать или отклонять cookie-файлы. Управление cookie-файлами различается в зависимости от используемого браузера.
  span При этом применяя технологию cookie, Администрация Сервисов не хранит и не использует никакие конкретные данные о Пользователях. Администрация Сервисов обращает внимание, что Пользователь  вправе настроить браузер на отказ от регистрации обращений к Сайту или на предупреждения о запросах на подобную регистрацию. Отключение «cookie» может повлечь невозможность доступа к Сайту.
  p 2.11. Если Администрация Сервисов не сможет никаким образом соотнести информацию, указанную в пунктах 2.9-2.10 Политики конфиденциальности с Пользователем Сервиса (физическим лицом), Администрация Сервисов не будет считать данную информацию персональными данными.
  p.chapter 3.  Цели обработки персональных данных Пользователей Сервисов
  p 3.1. Сервисы собирают и хранят только те персональные данные Пользователя Сервисов, которые необходимы для предоставления сервисов или исполнения соглашений и договоров с Пользователем Сервисов, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных в течение определенного законом срока.
  p 3.2. Персональные данные Пользователя Сервисов обрабатывает в следующих целях:
  p 3.2.1. Предоставления Пользователям Сервисов регистрации (авторизации) в сервисах, доступа к Сервисам и любых других случаях, связанных с такими действиями.
  p 3.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сервиса.
  p 3.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Серсивов, оказания услуг, обработку запросов и заявок от Пользователя Сервисов.
  p 3.2.4. Создания учетной записи, если Пользователь Сервиса дал согласие на создание учетной записи.
  p 3.2.5. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сервисов.
  p 3.2.6. Обеспечения работоспособности и безопасности Сервисов, для подтверждения совершаемых Пользователями действий, для предотвращения случаев мошенничества, компьютерных атак и иных злоупотреблений, а также для расследования таких случаев.
  p.chapter 4. Условия обработки персональных данных Пользователей Сервисов и передача их третьим лицам
  p 4.1. Сайт хранит персональные данные Пользователей в соответствии с внутренними регламентами конкретных сервисов.
  p 4.2. В отношении персональных данных Пользователя Сервиса сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем Сервиса данных о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь Сервисов соглашается с тем, что определенная часть его персональных данных становится общедоступной.
  p 4.3. Сервис вправе передать персональные данные Пользователя третьим лицам в следующих случаях:
  p 4.3.1. Пользователь Сервиса выразил согласие на такие действия.
  p 4.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем Сервисов.
  p 4.3.3. Передача предусмотрена законодательством Кыргызской Республики в рамках установленной законодательством процедуры.
  p 4.4. Настоящим Администрация Сервисов уведомляет Пользователя Сервиса, что в том случае, если Пользователь пожелал внести уточнения в его персональные данные, заблокировать или уничтожить их в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, либо в случае возникновения у Пользователя желания отозвать свое согласие на обработку персональных данных или устранить неправомерные действия Администрации Сервиса в отношении персональных данных Пользователя Сервиса, то Пользователь Сервиса должен направить Администрации Сервиса официальный запрос по адресу: 720001, г. Бишкек, пр. Чуй, 219 в качестве получателя необходимо указывать: ГНС при Министерстве финансов Кыргызской Республики.
  p В случае направления ГНС при Министерстве финансов Кыргызской Республики  официального запроса в тексте запроса Пользователю необходимо указать его: ФИО; номер основного документа, удостоверяющего личность Пользователя (субъекта персональных данных или его представителя), сведения о дате выдачи указанного документа и выдавшем его органе; сведения, подтверждающие участие Пользователя в отношениях с ГНС при Министерстве финансов Кыргызской Республики либо сведения, иным способом подтверждающие факт обработки ГНС при Министерстве финансов Кыргызской Республики персональных данных Пользователя, подпись гражданина (или его законного представителя).
  p 4.5. Администрация Сервисов обязуется немедленно прекратить обработку персональных данных Пользователя Сервисов с момента получения от Пользователя письменного заявления (отзыва) и в случае, если сохранение персональных данных более не требуется для целей обработки персональных данных, уничтожить их в срок и на условиях, установленных законодательством Кыргызской Республики.
  p 4.6. В случае достижения цели обработки персональных данных Администрация Сервисов обязуется прекратить обработку персональных данных и уничтожить их в срок и на условиях, установленных законодательством Кыргызской Республики.
  p 4.7. В случае отзыва Пользователем согласия на обработку персональных данных Администрация Сервисов вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в Законе Кыргызской Республике “Об информации персонального характера”.
  p.chapter 5. Обязательства сторон
  p 5.1. Пользователь Сайта обязуется:
  p 5.1.1. Предоставлять корректную и правдивую информацию о персональных данных, необходимую для пользования Сервисами.
  p 5.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.
  p 5.2. Администрация Сервисов обязуется:
  p 5.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящей Политики конфиденциальности.
  p 5.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного согласия Пользователя Сервисов, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя Сервисов, за исключением случаев, предусмотренных настоящей Политикой конфиденциальности.
  p 5.2.3. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю Сервисов, с момента обращения или запроса Пользователя Сервисов или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.
  p.chapter 6. Ответственность сторон
  p 6.1. Администрация Сервисов несет ответственность за умышленное разглашение персональных данных Пользователя Сервисов в соответствии с действующим законодательством Кыргызской Республики, за исключением случаев, предусмотренных настоящей Политикой конфиденциальности.
  p 6.2. В случае утраты или разглашения персональных данных Администрация Сервисов не несёт ответственность, если данная конфиденциальная информация:
  p 6.2.1. Стала публичным достоянием до её утраты или разглашения.
  p 6.2.2. Была получена от третьей стороны до момента её получения Администрацией Сервисов.
  p 6.2.3. Была разглашена с согласия Пользователя Сервисов.
  p 6.2.4. Была получена третьими лицами путем несанкционированного доступа к файлам Сервисов.
  p 6.3. Пользователь Сервисов несет ответственность за правомерность, корректность и правдивость предоставленных персональных данных в соответствии с законодательством Кыргызской Республики.
  p.chapter 7.  Сведения о реализуемых требованиях к защите персональных данных
  p 7.1. Администрация Сервисов принимает необходимые и достаточные организационные и технические меры для защиты персональных данных Пользователя Сервисов от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
  p.chapter 8. Разрешение споров
  p 8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сервисов и Администрацией Сервисов, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
  p 8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
  p 8.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с законодательством Кыргызской Республики.
  p 8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем Сервисов и Администрацией Сервисов применяется законодательство Кыргызской Республики.
  p.chapter 9.  Дополнительные условия
  p 9.1. Администрация Сервисов вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
  p 9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.
  p 9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на адрес электронной почты: #[a.link(href="mailto:sti@sti.gov.kg") sti@sti.gov.kg]
</template>

<script>
import Container from '@/components/Container.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    Container,
  },
};
</script>

<style lang="stylus" scoped>
  * {
    font-size 16px
  }
  a.link {
    text-decoration none;
  }
  table {
    border-collapse collapse
    border #5c5c5c
  }
  tr td:first-child, p.chapter {
    font-weight bold
  }
  td {
    padding 20px 0
  }
</style>
